import React, { useEffect, useState } from "react";
import Layout from "../../../components/Layouts/Layouts/Layout";
import DashboardHeader from "../../../components/basic/DashboardHeader";
import { Form, Table, Button, Pagination, Spinner } from "react-bootstrap";
import { RxCross2 } from "react-icons/rx";
import { MdDone } from "react-icons/md";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Badge from "react-bootstrap/Badge";
import { getURLbyEndPoint } from "../../../store/api";
import { IoMdDownload } from "react-icons/io";
import axios from "axios";
import DatePicker from "react-datepicker";
import "react-datepicker/dist/react-datepicker.css";

import {
  AiFillCheckCircle,
  AiFillCloseCircle,
  AiFillCheckSquare,
  AiFillCloseSquare,
} from "react-icons/ai";
import { formatDateTimeStandard } from "../../../components/basic/simpleFunctions";
import { PopUpModalButton } from "../../../components/basic/fileViewModal";
import { getAuthUserCookie } from "../../../store/services";

const ECOAdmin = () => {
  const [eCoData, setEcoData] = useState([]);
  const [reqStatus, setReqStatus] = useState("Processing");
  const [searchTerm, setSearchTerm] = useState("");
  const [refNo, setRefNo] = useState(null);
  const [refDate, setRefDate] = useState(null);
  const [courierDate, setCourierDate] = useState(null);
  const [currierCompanyName, setCurrierCompanyName] = useState(null);
  const [trackingNo, setTrackingNo] = useState(null);
  // const [finalRemark, setFinalRemark] = useState(null)

  // const handleInputChange = (e, itemId) => {
  //   const { name, value } = e.target;

  //   // Update the eCoData state with the new value for the current row
  //   setEcoData(prevData => {
  //     return prevData.map(item => {
  //       if (item._id === itemId) {
  //         return {
  //           ...item,
  //           [name]: value, // Update the specific field with the new value
  //         };
  //       }
  //       return item;
  //     });
  //   });
  // };

  const handleRefInputChange = (e, itemId) => {
    const { value } = e.target;
    setRefNo(value); // Update the ref state with the new value
  
    // Update the eCoData state to include the new refNo value
    setEcoData((prevData) => {
      return prevData.map((item) => {
        if (item._id === itemId) {
          return {
            ...item,
            refNo: value, // Use the updated value directly here
          };
        }
        return item;
      });
    });
  };
  

  const handleCurrierInputChange = (e, itemId, fieldName) => {
    const { value } = e.target;
    if (fieldName === "currierCompanyName") {
      setCurrierCompanyName(value);
    } else if (fieldName === "trackingNo") {
      setTrackingNo(value);
    }
    // Update the eCoData state to include the new courier details
    setEcoData((prevData) => {
      return prevData.map((item) => {
        if (item._id === itemId) {
          return {
            ...item,
            currierDetails: {
              ...item.currierDetails,
              [fieldName]: value, // Update the specific field with the new value
            },
          };
        }
        return item;
      });
    });
  };

  const handleDateChange = (date, itemId) => {
    // Update the refDate state with the new date value
    setRefDate(date);
    setCourierDate(date);

    // Update the eCoData state with the new date value for the current row
    setEcoData((prevData) => {
      return prevData.map((item) => {
        if (item._id === itemId) {
          return {
            ...item,
            dateFieldName: date,
            courierDate: date,
          };
        }
        return item;
      });
    });
  };

  //   // Update the eCoData state with the new date value for the current row
  //   setEcoData(prevData => {
  //     const updatedData = prevData.map(item => {
  //       if (item._id === itemId) {
  //         return {
  //           ...item,
  //           dateFieldName: date, // Replace 'dateFieldName' with the actual field name for the date in your data
  //         };
  //       }
  //       return item;
  //     });
  //     return updatedData;
  //   });
  // };

  //simple functions
  // const handleInputChange = (e, itemId) => {
  //   const { name, value, type } = e.target;

  //   // Check if the input type is a date
  //   if (type === 'date') {
  //     // For date inputs, handle the value differently
  //     const isoDate = new Date(value).toISOString(); // Convert date value to ISO string
  //     setEcoData((prevData) => {
  //       const updatedData = prevData.map((item) => {
  //         if (item._id === itemId) {
  //           return {
  //             ...item,
  //             [name]: isoDate, // Update the date field with the ISO date
  //           };
  //         }
  //         return item;
  //       });
  //       return updatedData;
  //     });
  //   } else {
  //     // For other types of inputs, update as usual
  //     setEcoData((prevData) => {
  //       const updatedData = prevData.map((item) => {
  //         if (item._id === itemId) {
  //           return {
  //             ...item,
  //             [name]: value,
  //           };
  //         }
  //         return item;
  //       });
  //       return updatedData;
  //     });
  //   }
  // };

  // const handleDateChange = (e) => {
  //   const newDate = e.target.value;
  //   setSelectedDate(newDate);
  //  };

  //function and featuress
  // const handleDownloadZip = async (userId) => {
  //   if (!userId) {
  //     alert("no files found");
  //     return;
  //   }

  //   try {
  //     const res = await axios.get(getURLbyEndPoint("downloadZipEco") + userId);

  //     if (res.data.status) {
  //       alert("here's zip file.");
  //     }
  //   } catch (error) {
  //     console.error("Error:", error);
  //   }
  // };
  const apiLoading = async (flag, userId) => {
    setEcoData((prevData) => {
      const updatedData = prevData.map((user) => {
        if (user._id === userId) {
          return { ...user, appDisApi: flag };
        }
        return user;
      });
      return updatedData;
    });
  };


  
  const handleApproveRejectMco = async (userId, status) => {
    apiLoading(true, userId);

    //finding user
    const clientData = eCoData.find((user) => user._id === userId);
    const userEmail =  getAuthUserCookie().email 

    try {
      let remarkValue = status ? "Documents Approved" : clientData.remark;

      const res = await axios.post(
        getURLbyEndPoint("approveRejectMco") + userId,
        {
          status: status,
          remark: remarkValue,
          currierCompanyName: currierCompanyName,
          trackingNo: trackingNo,
          refNo: refNo,
          courierDate: courierDate,
          approvedEmail: userEmail ,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (res.data.status) {
        alert(res.data.message);
        apiLoading(true, userId);

        window.location.reload();
      } else {
        alert(res.data.message);
      }
    } catch (error) {
      alert("Error while handling e-Co Data.");
      console.error("Error:", error);
    } finally {
      apiLoading(true, userId);
    }
  };



  
  const handleGenerateRef = async (userId, status) => {
    apiLoading(true, userId);

    //finding user
    const clientData = eCoData.find((user) => user._id === userId);

    try {
      let remarkValue = status
        ? "Courier Information Provided Shortly!"
        : clientData.remark;




      const res = await axios.post(
        getURLbyEndPoint("approveGenerateRef") + userId,
        {
          status: status,
          remark: remarkValue,
          dateFieldName: refDate,
          refNo: clientData.refNo,
        },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (res.data.status) {
        alert(res.data.message);
        apiLoading(true, userId);

        window.location.reload();
      } else {
        alert(res.data.message);
      }
    } catch (error) {
      alert("Error while handling e-Co Data.");
      console.error("Error:", error);
    } finally {
      apiLoading(false, userId);
    }
  };

  const fetchMcoData = async () => {
    try {
      const response = await axios.get(getURLbyEndPoint("getMasterMcoData"));
      console.log(response);
      if (response.status === 200) {
        const data = response.data.data;
        console.log(data);
        // Add properties to each object in the data.data array
        const modifiedData = data.map((item) => {
          if (
            item.isApproved === "Processing" ||
            item.isApproved === "Printing"
          ) {
            return {
              ...item,
              remark: "",
              appDisApi: false,
            };
          }

          return item;
        });

        
        setEcoData([...modifiedData]);
        console.log("result", modifiedData);
      } else {
        alert("Failed to fetch co data.");
      }
    } catch (error) {
      alert("Error fetching data:", error);
    }
  };

  //filtering data
  const filteredData = eCoData.filter((data) => {
    // Filter based on account status
    if (reqStatus !== "" && data.isApproved !== reqStatus) {
      return false;
    }
    // Filter based on app NO or forComRegion
    if (
      searchTerm !== "" &&
      !data.appNo.toLowerCase().includes(searchTerm.toLowerCase()) &&
      !data.forComRegion.toLowerCase().includes(searchTerm.toLowerCase())
    ) {
      return false;
    }
    return true;
  });

  //filter data here
  // Pagination logic
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10); // Assuming items per page is fixed

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentFiltered = filteredData.slice(indexOfFirstItem, indexOfLastItem);

  const totalPages = Math.ceil(filteredData.length / itemsPerPage);

  // Calculate the range of pages to display
  const calculateRange = (currentPage, totalPages) => {
    let startPage = Math.max(1, currentPage - 5);
    let endPage = Math.min(totalPages, currentPage + 4); // Adjusted to show 10 pages

    // Adjust if the range is too close to the beginning or end
    if (endPage - startPage < 9) {
      if (startPage === 1) {
        endPage = Math.min(10, totalPages);
      } else if (endPage === totalPages) {
        startPage = Math.max(totalPages - 9, 1);
      }
    }

    return { startPage, endPage };
  };

  const { startPage, endPage } = calculateRange(currentPage, totalPages);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handlePrevPage = () => {
    window.scrollTo(0, 100);
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
  };

  const handleNextPage = () => {
    window.scrollTo(0, 100);
    setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages));
  };

  useEffect(() => {
    fetchMcoData();
  }, []);
  return (
    <>
      <Layout>
        <div className="d-flex flex-column justify-content-center align-items-center px-3">
          <div className="main-width d-flex flex-column">
            <hr />
            <DashboardHeader
              heading="Services/ Semi-eCO"
              backUrl="/master-services"
            />
            <hr />
            <div className="d-flex justify-content-between align-items-center mb-3">
              <div className="d-flex gap-3">
                <Form.Check
                  inline
                  label="All"
                  name="group1"
                  type="radio"
                  checked={reqStatus === ""}
                  onChange={() => setReqStatus("")}
                />
                <Form.Check
                  inline
                  label="Processing"
                  name="group1"
                  type="radio"
                  checked={reqStatus === "Processing"}
                  onChange={() => setReqStatus("Processing")}
                  defaultChecked
                />
                <Form.Check
                  inline
                  label="Printing"
                  name="group1"
                  type="radio"
                  checked={reqStatus === "Printing"}
                  onChange={() => setReqStatus("Printing")}
                />
                <Form.Check
                  inline
                  label="Approved"
                  name="group1"
                  type="radio"
                  checked={reqStatus === "Approved"}
                  onChange={() => setReqStatus("Approved")}
                />
                <Form.Check
                  inline
                  label="Rejected"
                  name="group1"
                  type="radio"
                  checked={reqStatus === "Rejected"}
                  onChange={() => setReqStatus("Rejected")}
                />
              </div>
              <input
                className="form-control w-50"
                type="search"
                placeholder="Search By Exporter Name / Application No."
                aria-label="Search"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            </div>
            <div>
              <Table responsive striped bordered hover>
                <thead>
                  <tr className="text-center">
                    <th style={{ fontSize: "14px", whiteSpace: "nowrap" }}>
                      Sr No.
                    </th>
                    <th style={{ fontSize: "14px", whiteSpace: "nowrap" }}>
                      User
                      <br /> Name
                    </th>
                    <th style={{ fontSize: "14px", whiteSpace: "nowrap" }}>
                      Application No.
                      <br />& Date Time
                    </th>
                    <th style={{ fontSize: "14px", whiteSpace: "nowrap" }}>
                      Exporter
                    </th>
                    <th style={{ fontSize: "14px", whiteSpace: "nowrap" }}>
                      Invoice
                      <br /> No.
                    </th>
                    <th style={{ fontSize: "14px", whiteSpace: "nowrap" }}>
                      Final Destination
                    </th>
                    <th style={{ fontSize: "14px", whiteSpace: "nowrap" }}>
                      Israel Declaration
                    </th>
                    <th
                      style={{
                        fontSize: "14px",
                        width: "200px",
                        whiteSpace: "nowrap",
                      }}
                    >
                      Documents
                    </th>
                    <th style={{ fontSize: "14px", whiteSpace: "nowrap" }}>
                      Generate Ref. No
                    </th>
                    <th style={{ fontSize: "14px", whiteSpace: "nowrap" }}>
                      Delivery & Dispatch Details
                    </th>
                    <th style={{ fontSize: "14px", whiteSpace: "nowrap" }}>
                      Status
                    </th>
                    <th style={{ fontSize: "14px", whiteSpace: "nowrap" }}>
                      Action
                    </th>
                  </tr>
                </thead>

                {currentFiltered.length > 0 ? (
                  <>
                    {currentFiltered.map((data, index) => (
                      <tbody className="text-center">
                        <tr key="index">
                          <td>
                            {filteredData.length - indexOfFirstItem - index}.
                          </td>
                          <td>
                            <div>{data.email}</div>
                          </td>
                          <td>
                            <div>
                              {data.appNo}{" "}
                              <h6 className="text-success">
                                {formatDateTimeStandard(data.createdAt)}
                              </h6>
                            </div>
                          </td>
                          <td>{data.forComRegion}</td>
                          <td>{data.noAndDateInvoice}</td>
                          <td>{data.impCountry}</td>
                          <td>
                            {data.israeliDec === "true" ? (
                              <AiFillCheckSquare className="icon-size text-success" />
                            ) : data.israeliDec === "false" ? (
                              <AiFillCloseSquare className="icon-size text-danger" />
                            ) : (
                              <AiFillCloseSquare className="icon-size text-danger" />
                            )}
                          </td>

                          <td>
                            <div
                              className="d-flex gap-2
                          justify-content-center align-items-center mb-3"
                            >
                              <PopUpModalButton
                                title="semi-eCo"
                                fileLink={data.eCoFile}
                              />
                              <PopUpModalButton
                                title="Invoice"
                                fileLink={data.comInvoice}
                              />
                              <PopUpModalButton
                                title="Packing List"
                                fileLink={data.packingList}
                              />
                              <PopUpModalButton
                                title="Receipt"
                                fileLink={data.receipt}
                              />
                            </div>
                            <div
                              className="d-flex gap-2
                          justify-content-center align-items-center"
                            >
                              {data.addDoc1 && (
                                <PopUpModalButton
                                  title="Additional doc 1"
                                  fileLink={data.addDoc1}
                                />
                              )}
                              {data.addDoc2 && (
                                <PopUpModalButton
                                  title="Additional doc 2"
                                  fileLink={data.addDoc2}
                                />
                              )}
                              {data.refDoc && (
                                <PopUpModalButton
                                  title="Reference doc"
                                  fileLink={data.refDoc}
                                />
                              )}
                            </div>
                          </td>
                          <td>
                            {data.isApproved === "Approved" ||
                            data.isApproved === "Printing" ? (
                              <h6 className="text-primary">
                                Ref No: {data.refNo}
                                {/* {console.log(data.refNo)} */}
                                <br />
                                Date:{" "}
                                {new Date(data.dateFieldName).toLocaleDateString()}
                              </h6>
                            ) : data.isApproved === "Rejected" ? (
                              <h6>Request rejected</h6>
                            ) : (
                              <>
                                <Form.Control
                                  as="textarea"
                                  type="text"
                                  placeholder="Enter Ref No"
                                  rows={2}
                                  style={{ width: "150px" }}
                                  maxLength={50}
                                  onChange={(e) =>
                                    handleRefInputChange(e, data._id)
                                  }
                                  name="refNo"
                                />
                                <input
                                  type="date"
                                  id="myDate"
                                  className="form-control"
                                  onChange={(e) =>
                                    handleDateChange(e.target.value, data._id)
                                  }
                                  placeholderText="Select Date"
                                />
                              </>
                            )}
                          </td>

                          <td className="text-left">
                            {data.selectedDeliveryMode ===
                            "Collect From Chamber Desk" ? (
                              <h6>{data.selectedDeliveryMode}</h6>
                            ) : (
                              <div>

                                  <h6>Address:</h6>
                                  <h6>Mode: {data.selectedDeliveryMode}</h6>
                                  <h6>Address Type: {data.addressType}</h6>
                                <h6>{data.addressTypeAddress}</h6>
                                <h6>Pincode: {data.addressTypePincode}</h6>
                              </div>
                            )}
                          </td>

                          <td
                            className={
                              data.isApproved === "Rejected"
                                ? "fw-semibold text-danger"
                                : "fw-semibold text-success"
                            }
                          >
                            {data.isApproved}

                            {data.isApproved === "Approved" && 
                            data.selectedDeliveryMode !== "Collect From Chamber Desk" &&(
                              <div className="text-left text-nowrap mx-2">
                                <h6 className="text-black mb-0 pb-0">
                                  Courier Details
                                </h6>
                                <h6 className="text-black">
                                  Company: {data.currierCompanyName}
                                </h6>
                                <h6 className="text-black">
                                  Traking No: {data.trackingNo}
                                </h6>
                                <h6 className="text-black">
                                  Date: {data.dateFieldName}
                                </h6>
                                {data.approvedEmail &&
                   <span style={{fontSize: "0.7rem"}}>
                          by {data.approvedEmail}
                   </span>}
                              </div>
                            )}

                            {(data.isApproved === "Printing" &&
                              data.selectedDeliveryMode !==
                                "Collect From Chamber Desk") && (
                                <div className="my-2">
                                  <Form.Control
                                    type="text"
                                    placeholder="courier Company Name"
                                    rows={2}
                                    style={{ width: "150px" }}
                                    maxLength={50}
                                    onChange={(e) =>
                                      handleCurrierInputChange(
                                        e,
                                        data._id,
                                        "currierCompanyName"
                                      )
                                    }
                                    name="currierCompanyName"
                                    className="my-2"
                                  />
                                  <Form.Control
                                    type="text"
                                    placeholder="POD No/AirWay Bill No"
                                    rows={2}
                                    style={{ width: "150px" }}
                                    maxLength={50}
                                    onChange={(e) =>
                                      handleCurrierInputChange(
                                        e,
                                        data._id,
                                        "trackingNo"
                                      )
                                    }
                                    name="trackingNo"
                                    className="my-2"
                                  />

                                  <input
                                    type="date"
                                    id="myDate"
                                    className="form-control"
                                    onChange={(e) =>
                                      handleDateChange(e.target.value, data._id)
                                    }
                                    placeholderText="Select Date"
                                  />
                                </div>
                              )}
                          </td> 

                          <td>
                            {data.isApproved === "Approved" ||
                            data.isApproved === "Rejected" ? (
                              <>
                              <h6>
                                Remark :{" "}
                                <span className="text-secondary">
                                  {data.remark}
                                </span>
                              </h6>
                              {data.approvedEmail &&
                   <span style={{fontSize: "0.7rem"}}>
                          by {data.approvedEmail}
                   </span>}
                                </>
                            ) : (
                              <div className="max-content">
                                {data.isApproved === "Processing" && (
                                  <>
                                    <Form.Control
                                      as="textarea"
                                      type="text"
                                      placeholder="Enter a Remark"
                                      rows={3}
                                      maxLength={120}
                                      onChange={(e) => {
                                        const { value } = e.target;
                                        setEcoData((prevData) => {
                                          const updatedData = prevData.map(
                                            (user) => {
                                              if (user._id === data._id) {
                                                return {
                                                  ...user,
                                                  remark: value,
                                                };
                                              }
                                              return user;
                                            }
                                          );
                                          return updatedData;
                                        });
                                      }}
                                      value={data.remark}
                                      name="remark"
                                    />

                                    <div className="d-flex justify-content-center gap-3 w-100 mt-3">
                                      {data.appDisApi ? (
                                        <Spinner animation="border text-success" />
                                      ) : (
                                        <>
                                          <Button
                                            className="m-0 p-0 border-none"
                                            style={{
                                              backgroundColor: "transparent",
                                              boxShadow: "none",
                                            }}
                                            onClick={() =>
                                              handleGenerateRef(data._id, true)
                                            }
                                          >
                                            <AiFillCheckCircle className="w-100 icon-size green-color" />
                                          </Button>
                                          <Button
                                            className="m-0 p-0 border-none"
                                            style={{
                                              backgroundColor: "transparent",
                                              boxShadow: "none",
                                            }}
                                            onClick={() =>
                                              handleGenerateRef(data._id, false)
                                            }
                                          >
                                            <AiFillCloseCircle className="w-100 icon-size text-danger" />
                                          </Button>
                                        </>
                                      )}
                                    </div>
                                  </>
                                )}
                                {data.isApproved === "Printing" && (
                                  <div className="d-flex justify-content-center gap-3 w-100 mt-3">
                                    {data.appDisApi ? (
                                      <Spinner animation="border text-success" />
                                    ) : (
                                      <>
                                        <Button
                                          className="m-0 p-0 border-none"
                                          style={{
                                            backgroundColor: "transparent",
                                            boxShadow: "none",
                                          }}
                                          onClick={() =>
                                            handleApproveRejectMco(
                                              data._id,
                                              true
                                            )
                                          }
                                        >
                                          <AiFillCheckCircle className="w-100 icon-size green-color" />
                                        </Button>
                                        <Button
                                          className="m-0 p-0 border-none"
                                          style={{
                                            backgroundColor: "transparent",
                                            boxShadow: "none",
                                          }}
                                          onClick={() =>
                                            handleApproveRejectMco(
                                              data._id,
                                              false
                                            )
                                          }
                                        >
                                          <AiFillCloseCircle className="w-100 icon-size text-danger" />
                                        </Button>
                                      </>
                                    )}
                                  </div>
                                )}
                              </div>
                            )}
                          </td>
                        </tr>
                      </tbody>
                    ))}
                  </>
                ) : (
                  <tbody>
                    <tr>
                      <td colSpan={12} className="text-center">
                        No Matching Data
                      </td>
                    </tr>
                  </tbody>
                )}
              </Table>
            </div>
            {totalPages > 1 && (
              <div className="d-flex justify-content-start mt-2">
                <Pagination>
                  <Pagination.Prev
                    onClick={handlePrevPage}
                    disabled={currentPage === 1}
                  />
                  {Array.from(
                    { length: endPage - startPage + 1 },
                    (_, index) => (
                      <Pagination.Item
                        key={index + startPage}
                        active={index + startPage === currentPage}
                        onClick={() => handlePageChange(index + startPage)}
                      >
                        {index + startPage}
                      </Pagination.Item>
                    )
                  )}
                  <Pagination.Next
                    onClick={handleNextPage}
                    disabled={currentPage === totalPages}
                  />
                </Pagination>
              </div>
            )}
          </div>
        </div>
      </Layout>
    </>
  );
};

export default ECOAdmin;
