import { useNavigate } from "react-router-dom";
import DashboardHeader from "../../../components/basic/DashboardHeader";
import Layout from "../../../components/Layouts/Layouts/Layout";
import MasterNavigationBar from "../../../components/Layouts/Layouts/MasterServicesLayout";
import { getAuthUserCookie } from "../../../store/services";

const MasterEvent = () => {
  const navigate = useNavigate();
  return (
    <Layout>
      <MasterNavigationBar>
        <div className="d-flex flex-column px-3">
          <DashboardHeader heading="EVENTS" backUrl="/master-dashboard" />

          <hr />
          <div className="d-flex justify-content-around mb-2">
            <button
              onClick={() => {
                navigate("/event");
              }}
              disabled={!getAuthUserCookie().accessList.includes("ChamberBookingEvent")}
              type="button"
              className="bg-color border-0 mt-4 p-2 px-3 rounded-1"
              style={{ width: "35%" }}
            >
              <div className="gap-2 d-flex justify-content-center align-items-center">
                <span>CHAMBER EVENTS BOOKING</span>
              </div>
            </button>
            <button
              type="button"
                onClick={() => {
                  navigate("/b2b-virtual-forum");
                }}
              // disabled={!getAuthUserCookie().accessList.includes("B2BEvents")}
              className="bg-color border-0 mt-4 p-2 px-3 rounded-1"
              style={{ width: "35%" }}
            >
              B2B VIRTUAL FORUM
            </button>
          </div>
          <hr />
          <div className="d-flex justify-content-around mb-2">
            <button
              type="button"
              className="bg-color border-0 mt-4 p-2 px-3 rounded-1"
              style={{ width: "35%" }}
              disabled={!getAuthUserCookie().accessList.includes("SponsorShips")}
            >
              <div className="gap-2 d-flex justify-content-center align-items-center">
                <span>SPONSORSHIP</span>
              </div>
            </button>
          </div>
          <hr />
        </div>
      </MasterNavigationBar>
    </Layout>
  );
};

export default MasterEvent;