import React, { useEffect, useState } from "react";
import Layout from "../../../components/Layouts/Layouts/Layout";
import DashboardHeader from "../../../components/basic/DashboardHeader";
import { Form, Table, Button, Pagination, Spinner } from "react-bootstrap";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Badge from "react-bootstrap/Badge";
import { RxCross2 } from "react-icons/rx";
import { MdDone } from "react-icons/md";
import { MdDownload } from "react-icons/md";
import jsPDF from "jspdf";
import axios from "axios";
import { getURLbyEndPoint } from "../../../store/api";
import { IoMdDownload } from "react-icons/io";
import { getAuthUserCookie } from "../../../store/services";
import {
  AiFillCheckCircle,
  AiFillCloseCircle,
  AiFillCheckSquare,
  AiFillCloseSquare,
} from "react-icons/ai";
import { formatDateTimeStandard } from "../../../components/basic/simpleFunctions";
import {
  ConfirmModal,
  PopUpModalButton,
} from "../../../components/basic/fileViewModal";

const ECOSuperadmin = () => {
  const [eCoData, setEcoData] = useState([]);
  const [appDisApi, setAppDisApi] = useState(false);
  const [reqStatus, setReqStatus] = useState("Processing");
  const [searchTerm, setSearchTerm] = useState("");
  const [selDay, setSelDay] = useState("");

  //simple functions
  const handleInputChange = (itemId, name, value) => {
    setEcoData((prevData) => {
      const updatedData = prevData.map((item) => {
        if (item._id === itemId) {
          return {
            ...item,
            [name]: value,
          };
        }
        return item;
      });
      return updatedData;
    });
  };

  //function and featuress
  const handleDownloadPdf = async (userId, appNo) => {
    handleInputChange(userId, "isDownloadFileApi", true);
    if (!userId) {
      alert("No user ID provided");
      return;
    }

    try {
      const res = await axios.get(
        getURLbyEndPoint("downloadEcoFile") + userId,
        {
          responseType: "blob",
        }
      );
      console.log("res is here", res.headers);
      const data = res.data;

      if (!data) {
        alert("No files found for the given user");
        return;
      }

      // Create a blob URL for the PDF data
      const pdfUrl = window.URL.createObjectURL(new Blob([data]));

      // Create a link element to trigger the download
      const link = document.createElement("a");
      link.href = pdfUrl;
      link.setAttribute("download", `${appNo}_24-25.pdf`);

      // Append the link to the document body and click it
      document.body.appendChild(link);
      link.click();

      // Clean up by revoking the blob URL
      window.URL.revokeObjectURL(pdfUrl);
      handleInputChange(userId, "isDownloadFileApi", false);
    } catch (error) {
      console.error("Error fetching data:", error);
      alert("An error occurred while fetching data.");
      handleInputChange(userId, "isDownloadFileApi", false);
    } finally {
      handleInputChange(userId, "isDownloadFileApi", false);
    }
  };

  const handleApproveRejectEco = async (userId, status) => {
    setAppDisApi(true);
    //finding user
    const clientData = eCoData.find((user) => user._id === userId);
    const userEmail = getAuthUserCookie().email;

    try {
      const res = await axios.post(
        getURLbyEndPoint("approveRejectEco") + userId,
        { status, remark: clientData.remark, approvedEmail: userEmail },
        {
          headers: {
            "Content-Type": "application/json",
          },
        }
      );

      if (res.data.status) {
        alert(res.data.message);
        setAppDisApi(false);
        window.location.reload();
      } else {
        alert(res.data.message);
      }
    } catch (error) {
      alert("Error while handling e-Co Data.");
      console.error("Error:", error);
    } finally {
      setAppDisApi(false);
    }
  };

  const fetchEcoData = async () => {
    try {
      const response = await axios.get(getURLbyEndPoint("getMasterEcoData"));
      // console.log(response);
      if (response.status === 200) {
        const data = response.data.data;

        // Add properties to each object in the data.data array
        const modifiedData = data.map((item) => {
          if (item.isApproved === "Processing") {
            return {
              ...item,
              remark: "",
            };
          } else if (item.isApproved === "Approved") {
            return {
              ...item,
              isDownloadFileApi: false,
            };
          }
          return item;
        });

        setEcoData([...modifiedData]);
        // console.log("result", modifiedData);
      } else {
        alert("Failed to fetch co data.");
      }
    } catch (error) {
      alert("Error fetching data:", error);
    }
  };

  const calculateStartDate = (days) => {
    const today = new Date();
    const startDate = new Date(today); // Create a new Date object for immutability

    if (days === "yesterday") {
      startDate.setDate(today.getDate() - 1);
    } else if (days === "7days") {
      startDate.setDate(today.getDate() - 7);
    } else if (days === "14days") {
      startDate.setDate(today.getDate() - 14);
    }

    // Set time to start of day (midnight)
    startDate.setHours(0, 0, 0, 0);

    return startDate;
  };

  //filtering data
  const filteredData = eCoData.filter((data) => {
    // Filter based on account status
    if (reqStatus !== "" && data.isApproved !== reqStatus) {
      return false;
    }

    // Filter based on appNo, forComRegion, or email
    if (
      searchTerm !== "" &&
      !data.appNo.toLowerCase().includes(searchTerm.toLowerCase()) &&
      !data.forComRegion.toLowerCase().includes(searchTerm.toLowerCase()) &&
      !data.email.toLowerCase().includes(searchTerm.toLowerCase())
    ) {
      return false;
    }

    // Filter based on selected date range
    if (selDay !== "") {
      const startDate = calculateStartDate(selDay);
      const updatedAt = new Date(data.updatedAt);

      // Compare updatedAt date with startDate
      if (updatedAt < startDate) {
        return false;
      }
    }

    return true;
  });

  //filter data here
  // Pagination logic
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(10); // Assuming items per page is fixed

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentFiltered = filteredData.slice(indexOfFirstItem, indexOfLastItem);

  const totalPages = Math.ceil(filteredData.length / itemsPerPage);

  // Calculate the range of pages to display
  const calculateRange = (currentPage, totalPages) => {
    let startPage = Math.max(1, currentPage - 5);
    let endPage = Math.min(totalPages, currentPage + 4); // Adjusted to show 10 pages

    // Adjust if the range is too close to the beginning or end
    if (endPage - startPage < 9) {
      if (startPage === 1) {
        endPage = Math.min(10, totalPages);
      } else if (endPage === totalPages) {
        startPage = Math.max(totalPages - 9, 1);
      }
    }

    return { startPage, endPage };
  };

  const { startPage, endPage } = calculateRange(currentPage, totalPages);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handlePrevPage = () => {
    window.scrollTo(0, 100);
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
  };

  const handleNextPage = () => {
    window.scrollTo(0, 100);
    setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages));
  };

  useEffect(() => {
    fetchEcoData();
  }, []);
  return (
    <>
      <Layout> 
        <div className="d-flex flex-column justify-content-center align-items-center px-3">
          <div className="main-width d-flex flex-column">
            <hr />
            <DashboardHeader
              heading="Services/ Electronic COO (eCO)"
              backUrl="/master-services"
            />
            <hr />
            <div className="d-flex justify-content-between align-items-center mb-3">
              <div className="d-flex gap-3">
                <Form.Check
                  inline
                  label="All"
                  name="group1"
                  type="radio"
                  checked={reqStatus === ""}
                  onChange={() => setReqStatus("")}
                />
                <Form.Check
                  inline
                  label="Processing"
                  name="group1"
                  type="radio"
                  checked={reqStatus === "Processing"}
                  onChange={() => setReqStatus("Processing")}
                  defaultChecked
                />
                <Form.Check
                  inline
                  label="Approved"
                  name="group1"
                  type="radio"
                  checked={reqStatus === "Approved"}
                  onChange={() => setReqStatus("Approved")}
                />
                <Form.Check
                  inline
                  label="Rejected"
                  name="group1"
                  type="radio"
                  checked={reqStatus === "Rejected"}
                  onChange={() => setReqStatus("Rejected")}
                />
              </div>
              <div className="w-50 d-flex gap-3">
                <input
                  className="form-control"
                  type="search"
                  placeholder="Exporter Name/ Application No./ Email"
                  aria-label="Search"
                  value={searchTerm}
                  onChange={(e) => setSearchTerm(e.target.value)}
                />
                <Form.Select
                  className="form-control"
                  aria-label="selDay"
                  value={selDay}
                  onChange={(e) => setSelDay(e.target.value)}
                >
                  <option value="">Select an option</option>
                  <option value="today">Today</option>
                  <option value="yesterday">Yesterday</option>
                  <option value="7days">7 days</option>
                  <option value="14days">14 days</option>
                </Form.Select>
              </div>
            </div>
            <div>
              <Table responsive striped bordered hover>
                <thead>
                  <tr className="text-center">
                    <th style={{ fontSize: "14px", whiteSpace: "nowrap" }}>
                      Sr No.
                    </th>
                    <th style={{ fontSize: "14px", whiteSpace: "nowrap" }}>
                      User Name
                    </th>
                    <th style={{ fontSize: "14px", whiteSpace: "nowrap" }}>
                      Application No.
                      <br />
                      Date With Time
                    </th>
                    <th style={{ fontSize: "14px", whiteSpace: "nowrap" }}>
                      Exporter
                    </th>
                    <th style={{ fontSize: "14px", whiteSpace: "nowrap" }}>
                      Invoice No.
                    </th>
                    <th style={{ fontSize: "14px", whiteSpace: "nowrap" }}>
                      Final Destination
                    </th>
                    <th style={{ fontSize: "14px", whiteSpace: "nowrap" }}>
                      Israel Declaration
                    </th>
                    <th
                      style={{
                        fontSize: "14px",
                        width: "200px",
                        whiteSpace: "nowrap",
                      }}
                    >
                      Documents
                    </th>
                    <th style={{ fontSize: "14px", whiteSpace: "nowrap" }}>
                      Generate Ref. No
                    </th>
                    <th style={{ fontSize: "14px", whiteSpace: "nowrap" }}>
                      Status
                    </th>
                    <th style={{ fontSize: "14px", whiteSpace: "nowrap" }}>
                      Action
                    </th>
                  </tr>
                </thead>

                {currentFiltered.length > 0 ? (
                  <>
                    {currentFiltered.map((data, index) => (
                      <tbody className="text-center">
                        <tr key="index">
                          <td>
                            {filteredData.length - indexOfFirstItem - index}.
                          </td>
                          <td>
                            <div>{data.email}</div>
                          </td>
                          <td>
                            <div>
                              {data.appNo}{" "}
                              <h6 className="text-success">
                                {formatDateTimeStandard(data.createdAt)}
                              </h6>
                            </div>
                          </td>
                          <td>{data.forComRegion}</td>
                          <td>{data.noAndDateInvoice}</td>
                          <td>{data.impCountry}</td>
                          <td>
                            {data.israeliDec === "true" ? (
                              <AiFillCheckSquare className="icon-size text-success" />
                            ) : data.israeliDec === "false" ? (
                              <AiFillCloseSquare className="icon-size text-danger" />
                            ) : (
                              <AiFillCloseSquare className="icon-size text-danger" />
                            )}
                          </td>

                          <td>
                            <div
                              className="d-flex gap-2
                          justify-content-center align-items-center mb-3"
                            >
                              <PopUpModalButton
                                title="eCo"
                                fileLink={data.eCoFile}
                              />
                              <PopUpModalButton
                                title="Invoice"
                                fileLink={data.comInvoice}
                              />
                              <PopUpModalButton
                                title="Packing List"
                                fileLink={data.packingList}
                              />
                              <PopUpModalButton
                                title="Receipt"
                                fileLink={data.receipt}
                              />
                            </div>
                            <div
                              className="d-flex gap-2
                          justify-content-center align-items-center"
                            >
                              {data.addDoc1 && (
                                <PopUpModalButton
                                  title="Additional doc 1"
                                  fileLink={data.addDoc1}
                                />
                              )}
                              {data.addDoc2 && (
                                <PopUpModalButton
                                  title="Additional doc 2"
                                  fileLink={data.addDoc2}
                                />
                              )}
                              {data.refDoc && (
                                <PopUpModalButton
                                  title="Reference doc"
                                  fileLink={data.refDoc}
                                />
                              )}
                            </div>
                          </td>
                          <td>
                            {data.isApproved === "Approved" ? (
                              <h6 className="text-primary">
                                Ref No. : {data.refNo}{" "}
                              </h6>
                            ) : (
                              <>
                                <span>-----</span>
                              </>
                            )}
                          </td>
                          <td>
                            <h6
                              className={
                                data.isApproved === "Rejected"
                                  ? "fw-semibold text-danger"
                                  : "fw-semibold text-success"
                              }
                            >
                              {data.isApproved}
                            </h6>
                            {data.approvedEmail && (
                              <span style={{ fontSize: "0.7rem" }}>
                                by {data.approvedEmail}
                              </span>
                            )}
                          </td>

                          <td>
                            {data.isApproved === "Approved" ||
                            data.isApproved === "Rejected" ? (
                              <>
                                <h6>
                                  Remark :{" "}
                                  <span className="text-secondary">
                                    {data.remark}
                                  </span>
                                </h6>
                                {data.isDownloadFileApi ? (
                                  <Spinner animation="border text-success" />
                                ) : (
                                  <a
                                    href="#"
                                    onClick={() =>
                                      handleDownloadPdf(data._id, data.appNo)
                                    }
                                  >
                                    <IoMdDownload className="icon-size-2" />
                                  </a>
                                )}
                              </>
                            ) : (
                              <>
                                <div className="max-content">
                                  <Form.Control
                                    as="textarea"
                                    type="text"
                                    placeholder="Enter a Remark"
                                    rows={3}
                                    maxLength={120}
                                    onChange={(e) =>
                                      handleInputChange(
                                        data._id,
                                        e.target.name,
                                        e.target.value
                                      )
                                    }
                                    value={data.remark}
                                    name="remark"
                                  />

                                  {data.remark && (
                                    <div className="d-flex justify-content-center gap-3 w-100 mt-3">
                                      {appDisApi ? (
                                        <Spinner animation="border text-success" />
                                      ) : (
                                        <>
                                          <ConfirmModal
                                            handleApproveRejectEco={
                                              handleApproveRejectEco
                                            }
                                            userId={data._id}
                                          />
                                          {/* <Button
                                            className="m-0 p-0 border-none"
                                            style={{
                                              backgroundColor: "transparent",
                                              boxShadow: "none",
                                            }}
                                          >
                                            <AiFillCheckCircle
                                              className="w-100 icon-size green-color"
                                              onClick={() =>
                                                handleApproveRejectEco(
                                                  data._id,
                                                  true
                                                )
                                              }
                                            />
                                          </Button>
                                          <Button
                                            className="m-0 p-0 border-none"
                                            style={{
                                              backgroundColor: "transparent",
                                              boxShadow: "none",
                                            }}
                                          >
                                            <AiFillCloseCircle
                                              className="w-100 icon-size text-danger"
                                              onClick={() =>
                                                handleApproveRejectEco(
                                                  data._id,
                                                  false
                                                )
                                              }
                                            />
                                          </Button> */}
                                        </>
                                      )}
                                    </div>
                                  )}
                                </div>
                              </>
                            )}
                          </td>
                        </tr>
                      </tbody>
                    ))}
                  </>
                ) : (
                  <tbody>
                    <tr>
                      <td colSpan={11} className="text-center">
                        No Matching Data
                      </td>
                    </tr>
                  </tbody>
                )}
              </Table>
            </div>
            {totalPages > 1 && (
              <div className="d-flex justify-content-start mt-2">
                <Pagination>
                  <Pagination.Prev
                    onClick={handlePrevPage}
                    disabled={currentPage === 1}
                  />
                  {Array.from(
                    { length: endPage - startPage + 1 },
                    (_, index) => (
                      <Pagination.Item
                        key={index + startPage}
                        active={index + startPage === currentPage}
                        onClick={() => handlePageChange(index + startPage)}
                      >
                        {index + startPage}
                      </Pagination.Item>
                    )
                  )}
                  <Pagination.Next
                    onClick={handleNextPage}
                    disabled={currentPage === totalPages}
                  />
                </Pagination>
              </div>
            )}
          </div>
        </div>
        {/* <iframe
          title="ECO iFrame"
          src="https://eservices.aecci.org.in/?userId=65b5c79c49abc7b5b5ee1894"
          width="100%"
          height="1000px"
          frameBorder="0"
          scrolling="auto"
        ></iframe> */}
      </Layout>
    </>
  );
};

export default ECOSuperadmin;

// async function eCoStamp(eCoFileUrl) {
//   try {
//     // Download the PDF file from the URL
//     const response = await fetch(eCoFileUrl, { mode: 'cors' });
//     if (!response.ok) {
//       throw new Error(`Failed to fetch: ${response.statusText}`);
//     }

//     const blob = await response.blob();

//     // Convert the Blob into an object URL
//     const imageUrl = URL.createObjectURL(blob);

//     // Create a new jsPDF instance
//     const pdf = new jsPDF({
//       unit: 'mm',
//       format: 'a4',
//       orientation: 'portrait'
//     });

//     // Load the image as an object
//     const image = new Image();
//     image.crossOrigin = "Anonymous";
//     image.src = imageUrl;

//     // Once the image is loaded, draw it onto the PDF canvas
//     image.onload = function () {
//       const imgWidth = pdf.internal.pageSize.getWidth();
//       const imgHeight = pdf.internal.pageSize.getHeight();

//       // Draw the image as a background
//       pdf.addImage(this, 'JPEG', 0, 0, imgWidth, imgHeight);

//       // Add the "Check" text in the center of the PDF
//       pdf.setFontSize(20);
//       pdf.setTextColor(0, 0, 0); // Set text color to black
//       pdf.text('Check', imgWidth / 2, imgHeight / 2, { align: 'center' });

//       // Save the modified PDF
//       pdf.save("modified_document.pdf");

//       // Clean up the object URL
//       URL.revokeObjectURL(imageUrl);
//     };
//   } catch (error) {
//     console.error('Error:', error);
//   }
// }
