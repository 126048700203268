import React, { useEffect, useState } from "react";
import Layout from "../../../components/Layouts/Layouts/Layout";
import DashboardHeader from "../../../components/basic/DashboardHeader";
import { FaPen } from "react-icons/fa";
import { Modal, Button, Pagination, Table, Form } from "react-bootstrap";
import { BsEye } from "react-icons/bs";
import Container from "react-bootstrap/Container";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import { IoMdCheckmarkCircleOutline } from "react-icons/io";
import { ImCross } from "react-icons/im";
import { jsPDF } from "jspdf";
import html2pdf from "html2pdf.js";
import { saveAs } from "file-saver";
import visaLetterTemplate from "../../../assets/recommLetter.jpg";
import axios from "axios";
import { getURLbyEndPoint } from "../../../store/api";
import { AiFillCheckCircle, AiFillCloseCircle } from "react-icons/ai";

import {
  Document,
  Page,
  Text,
  View,
  StyleSheet,
  PDFViewer,
} from "@react-pdf/renderer";
import { formatDate } from "../profilePages/ClientSuperAdmin";
import FileViewModal from "../../../components/basic/fileViewModal";
import { breakAddressLines } from "../../../components/basic/textcustomized";

const RecommendationLetter = () => {
  const [recommendationData, setRecommendationData] = useState([]);
  const [reqStatus, setReqStatus] = useState("");
  const [searchTerm, setSearchTerm] = useState("");

  // Function to open the modal and set certificate URL
  const [showModal, setShowModal] = useState(false);
  const [modalTitle, setModalTitle] = useState("");
  const [file, setFile] = useState(null);

  const openModal = (title, file) => {
    setModalTitle(title);
    setFile(file);
    setShowModal(true);
  };
  const closeModal = () => {
    setShowModal(false);
  };

  const handleInputChange = (e, itemId) => {
    const { name, value } = e.target;
    setRecommendationData((prevData) => {
      const updatedData = prevData.map((item) => {
        if (item._id === itemId) {
          return {
            ...item,
            [name]: value,
          };
        }
        return item;
      });
      return updatedData;
    });
  };

  const handleFileChange = (event) => {
    const file = event.target.files[0];
    const userId = document.getElementById("fileInput").dataset.userId;

    console.log(file);

    if (
      file &&
      file.type === "application/pdf" &&
      file.size <= 5 * 1024 * 1024
    ) {
      setRecommendationData((prevClientData) => {
        // Update the membershipCertificate in the corresponding user
        const updatedClientData = prevClientData.map((user) =>
          user._id === userId ? { ...user, visaLetter: file } : user
        );

        console.log(updatedClientData); // Log the updated data

        return updatedClientData;
      });
    } else {
      // Handle invalid file
      alert("Please select a valid PDF file with size less than 5 MB.");
    }
  };

  //functions for pdf create
  const pdf = new jsPDF();

  const [pdfGenerated, setPdfGenerated] = useState(false);

  //logic for generating visLetter-------------------------------------------
  // ------------------------------------------------------------------------------
  // ------------------------------------------------------------------------------

  const handleGenerateVisa = async (userId) => {
    const updatedClientData = recommendationData.find(
      (user) => user._id === userId
    );

    if (!updatedClientData) {
      alert("User not found");
      return;
    }

    if (!updatedClientData.documentNo) {
      alert("please enter document no.");
      return;
    }

    const pdf = new jsPDF({
      unit: "mm",
      format: "a4",
      orientation: "portrait",
    });

    // Set background image
    const imgWidth = pdf.internal.pageSize.getWidth();
    const imgHeight = pdf.internal.pageSize.getHeight();
    pdf.addImage(visaLetterTemplate, "JPEG", 0, 0, imgWidth, imgHeight);

    pdf.setFontSize(12);
    pdf.setFont("helvetica", "bold");
    pdf.text(25, 60, `Ref: ${updatedClientData.documentNo}`);
    const date = formatDate(updatedClientData.updatedAt);
    pdf.text(150, 60, `Date: ${date}`);

    pdf.setFont("helvetica", "normal");
    pdf.text(25, 75, "To,");
    pdf.setFontSize(12);
    pdf.setFont("helvetica", "bold");
    pdf.text(25, 80, "His Excellency,");
    pdf.setFont("helvetica", "normal");

    const addressWidth = 100; // Width in mm
    const addressLines = pdf.splitTextToSize(
      updatedClientData.fullAddress,
      addressWidth
    );
    let yPosAddress = 85; // Starting y position for the full address
    // Print each line of the full address
    addressLines.forEach((line) => {
      pdf.text(25, yPosAddress, line);
      yPosAddress += 5; // Adjust the y position for the next line
    });

    pdf.setFont("helvetica", "normal");
    pdf.text(25, yPosAddress + 10, "Dear Sir,");

    //formatting texts
    const givenName =
      updatedClientData.givenName.charAt(0).toUpperCase() +
      updatedClientData.givenName.slice(1).toLowerCase();
    const surname =
      updatedClientData.surname.charAt(0).toUpperCase() +
      updatedClientData.surname.slice(1).toLowerCase();

    const paraText = `         M/S. ${updatedClientData.companyName} is the ${updatedClientData.selectMembership} Member of this chamber and their Membership number is ${updatedClientData.membershipNo}. We understand that their ${updatedClientData.designation} - ${updatedClientData.title} ${givenName} ${surname} is visiting ${updatedClientData.country} on a ${updatedClientData.purposeOfVisit}.`;
    // Print each line of the paragraph
    // Set a specific width for the paragraph
    const paraWidth = 165; // Width in mm
    const paraLines = pdf.splitTextToSize(paraText, paraWidth);
    let yPosPara = yPosAddress + 17; // Starting y position for the paragraph

    // Print each line of the paragraph
    paraLines.forEach((line) => {
      if (line.includes(updatedClientData.membershipNo)) {
        // Split the line to separate the part containing membership number
        const parts = line.split(updatedClientData.membershipNo);
        pdf.text(25, yPosPara, parts[0]); // Print the part before the membership number
        pdf.setTextColor(26, 61, 102); // Set text color to red
        pdf.setFont(undefined, "bold"); // Set font weight to bold
        pdf.text(
          25 + pdf.getTextWidth(parts[0]) - pdf.getTextWidth(parts[0]) * 0.086,
          yPosPara,
          ` ${updatedClientData.membershipNo}`
        ); // Print the membership number with bold red style
        pdf.setTextColor(0); // Reset text color to black
        pdf.setFont(undefined, "normal"); // Reset font weight to normal
        pdf.text(
          25 + pdf.getTextWidth(parts[0] + updatedClientData.membershipNo),
          yPosPara,
          parts[1]
        ); // Print the part after the membership number
      } else {
        pdf.text(25, yPosPara, line);
      }
      yPosPara += 6; // Adjust the y position for the next line
    });

    const text2 = `The Chamber, therefore, recommends that a visa be granted in favor of ${updatedClientData.title} ${givenName} ${surname}.`;
    const text2Lines = pdf.splitTextToSize(text2, paraWidth);
    let yPosText2 = yPosPara + 2;
    text2Lines.forEach((line) => {
      pdf.text(25, yPosText2, line);
      yPosText2 += 6; // Adjust the y position for the next line
    });

    pdf.text(25, yPosText2 + 2, "Details as on Indian Passport:");
    pdf.text(25, yPosText2 + 8, `Passport No. ${updatedClientData.passportNo}`);
    pdf.text(
      25,
      yPosText2 + 14,
      `Date of Issue: ${updatedClientData.dateOfIssue}`
    );
    pdf.text(
      25,
      yPosText2 + 20,
      `Date of Expiry: ${updatedClientData.dateOfExpiry}`
    );

    pdf.setFontSize(6);
    pdf.text(9.5, 289, `Ref: ${updatedClientData.documentNo}`);

    // //pdf ended-------------------------------------
    // ------------------------------------------------
    // ------------------------------------------------
    const blobPdf = pdf.output("blob", { encoding: "raw" });

    // Convert Blob to File
    const pdfVisaLetter = blobToFile(blobPdf, "visaLetter.pdf");

    //seeting like this so state will update
    setRecommendationData((prevClientData) =>
      prevClientData.map((user) =>
        user._id === userId
          ? {
            ...user,
            visaLetter: pdfVisaLetter,
          }
          : user
      )
    );
  };
  //logic for generating reciept-------------------------------------------
  // ------------------------------------------------------------------------------
  // ------------------------------------------------------------------------------

  const generatePdf = async (userId) => {
    const updatedClientData = recommendationData.find(
      (user) => user._id === userId
    );

    if (!updatedClientData) {
      alert("User not found");
      return;
    }

    if (!updatedClientData.receiptNo) {
      alert("please enter receipt no.");
      return;
    }
    if (!updatedClientData.documentNo) {
      alert("please enter document no.");
      return;
    }

    if (!updatedClientData.dateAndTime) {
      alert("please enter date and time.");
      return;
    }
    if (!updatedClientData.paidBy) {
      alert("please enter paid by field");
      return;
    }

    const pdf = new jsPDF({
      unit: "mm",
      format: "a4",
      orientation: "landscape", // Change this to portrait if needed
    });

    // First Page
    pdf.setTextColor(64, 64, 64);
    pdf.setFontSize(24);
    pdf.setFont("helvetica", "bold");
    pdf.text("Asian Exporters' Chamber of Commerce and Industry", 46, 12);
    pdf.setFont("helvetica", "normal");
    pdf.setFontSize(14);
    pdf.text(
      "(Recognized by Ministry of Commerce & Industry, Govt. of India)",
      80,
      20
    );
    pdf.text(
      "Office: 604, 6th Floor, Hilton Centre, Plot No. 68, Sector-11, CBD Belapur, Navi Mumbai, Maharashtra",
      40,
      26
    );
    pdf.text(
      "info@aecci.org.in, www.aecci.org.in Tel. No.: 022-41271145/46.",
      80,
      34
    );
    pdf.text("Company CIN No. U91900MH2015NPL265816", 100, 40);
    pdf.setFont("helvetica", "bold");
    pdf.setFontSize(20);
    pdf.text("INVOICE CUM RECEIPT", 110, 55);
    pdf.setLineWidth(0.5); // Set the line width as needed
    pdf.line(20, 58, 280, 58);

    const dateAndTime = new Date(updatedClientData.dateAndTime);
    const formattedDate = `${dateAndTime.getDate()}-${new Intl.DateTimeFormat(
      "en-US",
      { month: "short" }
    ).format(dateAndTime)}-${dateAndTime.getFullYear()}`;
    const formattedTime = `${dateAndTime.getHours()}:${String(
      dateAndTime.getMinutes()
    ).padStart(2, "0")}:${String(dateAndTime.getSeconds()).padStart(2, "0")}`;

    // main invoice starts here
    pdf.setFontSize(14);
    pdf.setFont("helvetica", "normal");
    pdf.text(`Receipt No : ${updatedClientData.receiptNo}`, 20, 65);
    pdf.text("Date ", 210, 65);
    pdf.text(`: ${formattedDate}`, 240, 65);

    pdf.text(`Document No : ${updatedClientData.documentNo}`, 20, 71);
    pdf.text("Time ", 210, 71);
    pdf.text(`: ${formattedTime}`, 240, 71);

    pdf.text(`Received From : ${updatedClientData.companyName}`, 20, 77);
    pdf.text("Paid By ", 210, 77);
    pdf.text(`: ${updatedClientData.paidBy}`, 240, 77);

    // pdf.text(`Address : ${updatedClientData.fullAddress}`, 20, 83);
    pdf.text(`State : ${updatedClientData.placeOfIssue}`, 230, 85, {
      align: "right",
    });

    pdf.text(`GSTIN : ${updatedClientData.gstNo}`, 20, 110);
    pdf.text(`Membership No : ${updatedClientData.membershipNo}`, 280, 110, {
      align: "right",
    });

    //table starts here
    // Draw a rectangle as the background for the table
    // Draw a rectangle as the background for the table header
    const colWidths = [30, 90, 30, 30, 30];

    pdf.setDrawColor(0, 0, 0); // Set border color to black
    pdf.rect(20, 115, 260, 10);

    // Add text in the header cells
    pdf.text("Sr No", 22, 122);
    pdf.text("Particulars", 47, 122);
    pdf.text("Quantity", 177, 122);
    pdf.text("Rate", 217, 122);
    pdf.text("Amount", 252, 122);

    // Draw vertical lines between the columns
    pdf.line(45, 115, 45, 170);
    pdf.line(175, 115, 175, 170);
    pdf.line(215, 115, 215, 170);
    pdf.line(250, 115, 250, 170);
    pdf.line(280, 115, 280, 170);

    // Draw a rectangle as the background for the table content
    pdf.rect(20, 125, 260, 35); // Draw an empty rectangle for the content

    pdf.text("1", 22, 132);
    pdf.text("Visa Recommendation Letter", 47, 132);
    pdf.setFontSize(12);
    pdf.text(`(Country : ${updatedClientData.country})`, 47, 137);
    pdf.setFontSize(14);
    pdf.text("1 nos", 177, 132);
    pdf.text(`${updatedClientData.amount}`, 217, 132);
    pdf.text(`${updatedClientData.amount}`, 252, 132);

    //footer making here
    pdf.rect(20, 160, 260, 10);
    pdf.rect(20, 170, 260, 10);

    pdf.text("Total Amount", 47, 167);
    pdf.text(`${updatedClientData.amount}`, 252, 167);

    pdf.setFontSize(12);
    pdf.setFont("helvetica", "italic");

    const amountInWords = convertAmountToWords(updatedClientData.amount);
    pdf.text(`Amount in Words(INR): ${amountInWords} Only.`, 22, 177);

    pdf.setFontSize(12);
    pdf.setFont("helvetica", "normal");
    pdf.setFontSize(12);
    pdf.setFont("helvetica", "italic");
    pdf.text(
      "for Asian Exporters' Chamber of Commerce and Industry",
      280,
      185,
      {
        align: "right",
      }
    );
    pdf.text("Signature is not require for printed.", 280, 195, {
      align: "right",
    });

    const blobPdf = pdf.output("blob", { encoding: "raw" });

    // Convert Blob to File
    const pdfFile = blobToFile(blobPdf, "receipt.pdf");

    //seeting like this so state will update
    setRecommendationData((prevClientData) =>
      prevClientData.map((user) =>
        user._id === userId
          ? {
            ...user,
            receiptFile: pdfFile,
          }
          : user
      )
    );

    setPdfGenerated(true);
  };

  //Accept visa recommendation logic and function are here
  const handleAcceptVisa = async (userId) => {
    const updatedClientData = recommendationData.find(
      (user) => user._id === userId
    );

    const validationRulesProceedPayment = {
      visaLetter: {
        message: "Please upload visaLetter.",
        isValid: () =>
          updatedClientData.deliveryMode !== "Digital Copy" ||
          updatedClientData.visaLetter !== null,
      },

      receiptNo: {
        message: "Please upload receiptNo.",
        isValid: () =>
          updatedClientData.deliveryMode !== "Digital Copy" ||
          updatedClientData.receiptNo.trim() !== "",
      },

      receiptFile: {
        message: "Please upload receiptFile.",
        isValid: () =>
          updatedClientData.deliveryMode !== "Digital Copy" ||
          updatedClientData.receiptFile !== null,
      },

      documentNo: {
        message: "Please Enter Document number.",
        isValid: () => updatedClientData.documentNo.trim() !== "",
      },
      dateAndTime: {
        message: "Please Enter Date and Time.",
        isValid: () => updatedClientData.dateAndTime.trim() !== "",
      },
      remark: {
        message: "Please Enter remark.",
        isValid: () => updatedClientData.remark.trim() !== "",
      },
    };

    for (const field in validationRulesProceedPayment) {
      const { message, isValid } = validationRulesProceedPayment[field];
      if (!isValid()) {
        alert(message);
        return;
      }
    }

    const formData = new FormData();

    // Append form data
    formData.append("userId", updatedClientData._id);
    formData.append("documentNo", updatedClientData.documentNo);
    formData.append("refNo", updatedClientData.refNo);

    formData.append("dateAndTime", updatedClientData.dateAndTime);
    formData.append("remark", updatedClientData.remark);

    if (updatedClientData.deliveryMode === "Digital Copy") {
      formData.append("visaLetter", updatedClientData.visaLetter);
      formData.append("receiptNo", updatedClientData.receiptNo);
      formData.append("receiptFile", updatedClientData.receiptFile);
    }

    try {
      const res = await axios.post(
        getURLbyEndPoint("acceptVisaLetter"),
        formData,
        {
          headers: {
            "Content-Type": "multipart/form-data",
          },
        }
      );
      if (res.data.status) {
        alert("Accepted successfully");
        window.location.reload();
      }
    } catch (error) {
      alert("Server is not responding!!");
      if (error.response) {
        console.error("Response error:", error.response.data);
        console.error("Status code:", error.response.status);
      } else if (error.request) {
        console.error("No response received:", error.request);
      } else {
        console.error("Request setup error:", error.message);
      }
    }
  };

  //Reject  visa recommendation logic and function are here
  const handleRejectVisa = async (userId) => {
    const updatedClientData = recommendationData.find(
      (user) => user._id === userId
    );

    if (!userId) {
      alert("Please provide a userId before rejecting.");
      return;
    }

    if (!updatedClientData.remark) {
      alert("Please provide a remark before rejecting.");
      return;
    }

    try {
      const res = await axios.put(getURLbyEndPoint("rejectVisaLetter"), {
        userId: userId,
        remark: updatedClientData.remark,
      });
    } catch (error) {
      alert("Server is not responding!!");
      if (error.response) {
        console.error("Response error:", error.response.data);
        console.error("Status code:", error.response.status);
      } else if (error.request) {
        console.error("No response received:", error.request);
      } else {
        console.error("Request setup error:", error.message);
      }
    }

    console.log(updatedClientData);
  };

  // //updating visa dat function for printing correctly
  // ---------------------------------------------------------
  // ---------------------------------------------------------

  const handleVisaDataUpdate = async (userId) => {
    const selectedClient = recommendationData.find(
      (client) => client._id === userId
    );
    const { addressTo, fullAddress } = selectedClient;
    try {
      const res = await axios.put(getURLbyEndPoint("visaDataUpdate") + userId, {
        addressTo,
        fullAddress,
      });
      alert(res.data.message);

      if (res.data.status) {
        setRecommendationData((prevClientData) =>
          prevClientData.map((user) =>
            user._id === userId
              ? {
                ...user,
                isEditingFields: false,
                visaLetter: null,
              }
              : user
          )
        );
      }
    } catch (error) {
      alert("Something went wrong");
      console.error("Error:", error);
    }
  };

  //rendering all data
  const fetchRecommendationLetterData = async () => {
    try {
      const response = await axios.get(
        getURLbyEndPoint("getVisaRecommendationDataMaster")
      );
      if (response.status === 200) {
        const data = response.data;

        // Add properties to each object in the data.data array
        const modifiedData = data.data.map((item) => {
          if (item.status === "Processing") {
            return {
              ...item,
              visaLetter: null,
              remark: "",
              receiptNo: "",
              documentNo: null,
              dateAndTime: "",
              receiptFile: null,
              paidBy: "",
              isEditingFields: false,
            };
          }
          return item;
        });

        setRecommendationData([...modifiedData]);
        console.log("Recommendation Letter data:", modifiedData);
      } else {
        console.error("Failed to fetch registered users data.");
      }
    } catch (error) {
      console.error("Error fetching data:", error);
    }
  };
  useEffect(() => {
    if (pdfGenerated) {
      // Do any additional actions or navigation after PDF is generated
    }
  }, [pdfGenerated]);

  useEffect(() => {
    fetchRecommendationLetterData();
  }, []);

  //adding filter on data
  // const filteredData = recommendationData.filter(data =>
  //   searchTerm !== "" ?
  //   (data.documentNo && data.documentNo.toLowerCase().includes(searchTerm.toLowerCase())) :
  //   true
  // );

  //filtering data
  const filteredData = recommendationData.filter((data) => {
    // Filter based on account status
    if (reqStatus !== "" && data.status !== reqStatus) {
      return false;
    }
    // Filter based on app NO
    if (
      searchTerm !== "" &&
      data.documentNo && // Check if documentNo is defined
      !data.documentNo.toLowerCase().includes(searchTerm.toLowerCase())
    ) {
      return false;
    }
    return true;
  });

  // Pagination logic
  const [currentPage, setCurrentPage] = useState(1);
  const [itemsPerPage] = useState(5); // Change to 5 items per page

  const indexOfLastItem = currentPage * itemsPerPage;
  const indexOfFirstItem = indexOfLastItem - itemsPerPage;
  const currentFiltered = filteredData.slice(indexOfFirstItem, indexOfLastItem);

  const totalPages = Math.ceil(filteredData.length / itemsPerPage);

  // Calculate the range of pages to display
  const calculateRange = (currentPage, totalPages) => {
    let startPage = Math.max(1, currentPage - 2); // Adjusted to show 5 pages
    let endPage = Math.min(totalPages, currentPage + 2); // Adjusted to show 5 pages

    // Adjust if the range is too close to the beginning or end
    if (endPage - startPage < 4) {
      if (startPage === 1) {
        endPage = Math.min(5, totalPages);
      } else if (endPage === totalPages) {
        startPage = Math.max(totalPages - 4, 1);
      }
    }

    return { startPage, endPage };
  };

  const { startPage, endPage } = calculateRange(currentPage, totalPages);

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber);
  };

  const handlePrevPage = () => {
    window.scrollTo(0, 100);
    setCurrentPage((prevPage) => Math.max(prevPage - 1, 1));
  };

  const handleNextPage = () => {
    window.scrollTo(0, 100);
    setCurrentPage((prevPage) => Math.min(prevPage + 1, totalPages));
  };

  return (
    <>
      <Layout>
        <div className="d-flex justify-content-center align-items-start">
          <div className="py-1 align-items-center justify-content-center main-width">
            <hr />
            <DashboardHeader
              heading="Services/ Recommendation Letter"
              backUrl="/master-services"
            />
            <hr />

            <div className="d-flex justify-content-between align-items-center mb-3">
              <div className="d-flex gap-3">
                <Form.Check
                  inline
                  label="All"
                  name="group1"
                  type="radio"
                  defaultChecked
                  checked={reqStatus === ""}
                  onChange={() => setReqStatus("")}
                />
                <Form.Check
                  inline
                  label="Processing"
                  name="group1"
                  type="radio"
                  checked={reqStatus === "Processing"}
                  onChange={() => setReqStatus("Processing")}
                />{" "}
                <Form.Check
                  inline
                  label="Approved"
                  name="group1"
                  type="radio"
                  checked={reqStatus === "Approved"}
                  onChange={() => setReqStatus("Approved")}
                />{" "}
                <Form.Check
                  inline
                  label="REJECTED"
                  name="group1"
                  type="radio"
                  checked={reqStatus === "Rejected"}
                  onChange={() => setReqStatus("Rejected")}
                />{" "}
              </div>
              <input
                className="form-control w-25"
                type="search"
                placeholder="Search By Application No."
                aria-label="Search"
                value={searchTerm}
                onChange={(e) => setSearchTerm(e.target.value)}
              />
            </div>

            {Array.isArray(recommendationData) &&
              recommendationData.length > 0 ? (
              <Table responsive striped bordered hover className="text-center">
                <thead>
                  <tr>
                    <th className="align-middle">SR NO.</th>
                    <th className="align-middle">Company Details</th>
                    <th className="align-middle">Application Details</th>
                    <th className="align-middle">Designation & Passport</th>
                    <th className="align-middle">Documents</th>
                    <th className="align-middle">Payment Details</th>
                    <th className="align-middle">Delivery Mode</th>
                    <th className="align-middle">Letter & receipt </th>
                    <th className="align-middle">
                      <h6 style={{ fontWeight: "bold", width: "max-content" }}>
                        TimeStamp & Remark
                      </h6>
                    </th>
                  </tr>
                </thead>
                <tbody>
                  {Array.isArray(recommendationData) &&
                    recommendationData.length > 0 ? (
                    (() => {
                      const filteredData = recommendationData.filter((data) => {
                        if (reqStatus === "") return true; // Show all data
                        return data.status === reqStatus; // Filter based on status
                      });

                      if (filteredData.length > 0) {
                        return currentFiltered.map((data, index) => (
                          <tr key={index}>
                            <th className="text-start">
                              {filteredData.length - indexOfFirstItem - index}.{" "}
                              <div className="max-content">
                                Created at:
                                <br />
                                <span className="text-success">
                                  {new Intl.DateTimeFormat("en-GB", {
                                    year: "numeric",
                                    month: "numeric",
                                    day: "numeric",
                                    hour: "numeric",
                                    minute: "numeric",
                                  }).format(new Date(data.createdAt))}
                                </span>
                              </div>
                            </th>
                            <td>
                              <div className="text-start max-content">
                                <h6>
                                  Company Name :{" "}
                                  <span className="text-secondary">
                                    {data.companyName}
                                  </span>
                                </h6>
                                <h6>
                                  Membership No. :{" "}
                                  <span className="text-secondary">
                                    {data.membershipNo}
                                  </span>
                                </h6>
                              </div>
                            </td>
                            <td>
                              <div className="text-start max-content">
                                <h6>
                                  Region :{" "}
                                  <span className="text-secondary">
                                    {data.region}
                                  </span>
                                </h6>
                                <h6>
                                  Country :{" "}
                                  <span className="text-secondary">
                                    {" "}
                                    {data.country}
                                  </span>
                                </h6>
                                {data.isEditingFields ? (
                                  <>
                                    <div className="d-flex align-items-center">
                                      <h6 className="text-nowrap">
                                        Address To :
                                      </h6>{" "}
                                      <Form.Select
                                        required
                                        className="ms-2"
                                        value={data.addressTo}
                                        onChange={(e) =>
                                          setRecommendationData(
                                            (prevClientData) =>
                                              prevClientData.map((user) =>
                                                user._id === data._id
                                                  ? {
                                                    ...user,
                                                    addressTo: e.target.value,
                                                  }
                                                  : user
                                              )
                                          )
                                        }
                                      >
                                        <option value="">
                                          Select Address to
                                        </option>
                                        <option value="High Comission">
                                          High Comission
                                        </option>
                                        <option value="Embassy">Embassy</option>
                                        <option value="Consulate">
                                          Consulate
                                        </option>
                                      </Form.Select>
                                    </div>
                                    <h6>Full Address:</h6>
                                    <div className="w-100">
                                      <Form.Control
                                        as="textarea"
                                        className="form-control mb-2 w-100"
                                        required
                                        type="text"
                                        rows={3}
                                        style={{ resize: "none" }}
                                        placeholder={
                                          data.addressTo === "High Comission"
                                            ? `High Commission of the Federal Republic of Nigeria,\nEP-4, Chandragupta Marg\nChanakyapuri\nNew Delhi - 110021`
                                            : data.addressTo === "Embassy"
                                              ? `Embassy of Afghanistan\n5/50F, Shantipath\nChanakyapuri\nNew Delhi-110021`
                                              : data.addressTo === "Consulate"
                                                ? `The Royal Consulate General of Saudi Arabia\n4th Floor, Maker Tower,\nF Wing, Cuffe Parade,\nMumbai, Maharashtra 400005`
                                                : ``
                                        }
                                        value={data.fullAddress}
                                        onChange={(e) =>
                                          setRecommendationData(
                                            (prevClientData) =>
                                              prevClientData.map((user) =>
                                                user._id === data._id
                                                  ? {
                                                    ...user,
                                                    fullAddress:
                                                      e.target.value,
                                                  }
                                                  : user
                                              )
                                          )
                                        }
                                        onKeyDown={(e) => {
                                          if (e.key === "Enter") {
                                            const lines =
                                              e.target.value.split("\n");
                                            if (lines.length >= 4) {
                                              e.preventDefault();
                                            }
                                          }
                                        }}
                                      />
                                    </div>
                                  </>
                                ) : (
                                  <>
                                    <h6>
                                      Address To :{" "}
                                      <span className="text-secondary">
                                        {" "}
                                        {data.addressTo}
                                      </span>
                                    </h6>
                                    <h6 className="mb-0">Full Address:</h6>

                                    <h6
                                      className="text-secondary"
                                      style={{ whiteSpace: "pre-line" }}
                                    >
                                      {data.fullAddress}
                                    </h6>
                                  </>
                                )}
                                {/* 
                                  //setting for buttons for updating fields---------------------------
                                  --------------------------------------------------------------------
                                  -------------------------------------------------------------------- */}
                                {data.status === "Processing" && (
                                  <>
                                    {data.isEditingFields ? (
                                      <div className="d-flex gap-2">
                                        <Button
                                          variant="success"
                                          onClick={() =>
                                            handleVisaDataUpdate(data._id)
                                          }
                                        >
                                          Update
                                        </Button>
                                        <Button
                                          variant="danger"
                                          onClick={() =>
                                            setRecommendationData(
                                              (prevClientData) =>
                                                prevClientData.map((user) =>
                                                  user._id === data._id
                                                    ? {
                                                      ...user,
                                                      isEditingFields: false,
                                                    }
                                                    : user
                                                )
                                            )
                                          }
                                        >
                                          Cancel
                                        </Button>
                                      </div>
                                    ) : (
                                      <Button
                                        className="fs-6 py-1"
                                        onClick={() =>
                                          setRecommendationData(
                                            (prevClientData) =>
                                              prevClientData.map((user) =>
                                                user._id === data._id
                                                  ? {
                                                    ...user,
                                                    isEditingFields: true,
                                                  }
                                                  : user
                                              )
                                          )
                                        }
                                      >
                                        EDIT
                                      </Button>
                                    )}
                                  </>
                                )}

                                {/* Add other address properties from data */}
                              </div>
                            </td>
                            <td>
                              <div className="text-start max-content">
                                <h6>
                                  Designation :{" "}
                                  <span className="text-secondary">
                                    {data.designation}
                                  </span>
                                </h6>

                                <h6>
                                  Name :{" "}
                                  <span className="text-secondary">
                                    {" "}
                                    {data.title} {data.givenName} {data.surname}
                                  </span>
                                </h6>
                                <h6>
                                  Passport No. :{" "}
                                  <span className="text-secondary">
                                    {" "}
                                    {data.passportNo}
                                  </span>
                                </h6>
                                <h6>
                                  PoV :{" "}
                                  <span className="text-secondary">
                                    {" "}
                                    {data.purposeOfVisit}
                                  </span>
                                </h6>
                                <h6>
                                  DoI :{" "}
                                  <span className="text-secondary">
                                    {" "}
                                    {data.dateOfIssue}
                                  </span>
                                </h6>
                                <h6>
                                  DoE :{" "}
                                  <span className="text-secondary">
                                    {" "}
                                    {data.dateOfExpiry}
                                  </span>
                                </h6>
                              </div>
                            </td>
                            <td>
                              <div className="text-start max-content d-flex flex-column">
                                {/* 
                                //modals----------------------------------------
                                ------------------------------------------------
                                ------------------------------------------------ */}
                                <a
                                  href="#"
                                  onClick={() =>
                                    openModal(
                                      "Requesting Letter",
                                      data.requestingLetter
                                    )
                                  }
                                >
                                  Requesting Letter
                                </a>{" "}
                                <a
                                  href="#"
                                  onClick={() =>
                                    openModal(
                                      "Invitation Letter",
                                      data.invitationLetter
                                    )
                                  }
                                >
                                  Invitation Letter
                                </a>{" "}
                                <a
                                  href="#"
                                  onClick={() =>
                                    openModal(
                                      "Passport Copies",
                                      data.copyOfPassport
                                    )
                                  }
                                >
                                  Passport Copies
                                </a>{" "}
                                <a
                                  href="#"
                                  onClick={() =>
                                    openModal(
                                      "Export Performance",
                                      data.exportPerformance
                                    )
                                  }
                                >
                                  Export Performance
                                </a>{" "}
                                <a
                                  href="#"
                                  onClick={() =>
                                    openModal(
                                      "Company Profile",
                                      data.comProfile
                                    )
                                  }
                                >
                                  Company Profile
                                </a>{" "}
                                {data.country === "Saudi Arabia" && (
                                  <>
                                    <a
                                      href="#"
                                      onClick={() =>
                                        openModal(
                                          "Additional Letter",
                                          data.additionalDoc
                                        )
                                      }
                                    >
                                      Additional Letter
                                    </a>{" "}
                                    <a
                                      href="#"
                                      onClick={() =>
                                        openModal(
                                          "Reference Letter",
                                          data.refDoc
                                        )
                                      }
                                    >
                                      Reference Letter
                                    </a>{" "}
                                  </>
                                )}
                              </div>
                            </td>
                            <td>
                              <div className="text-start max-content">
                                <h6>
                                  Amount :{" "}
                                  <span className="text-secondary">
                                    {" "}
                                    {data.amount}
                                  </span>
                                </h6>
                              </div>
                            </td>
                            <td>
                              <div className="text-start max-content">
                                <h6>{data.deliveryMode}</h6>
                              </div>
                            </td>

                            <td>
                              {/* ------------------------------Visa Letter----------------------------- */}

                              {data.status === "Approved" ||
                                data.status === "Rejected" ? (
                                <>
                                  {data.status === "Approved" ? (
                                    <>
                                      {data.deliveryMode === "Digital Copy" ? (
                                        <div className="d-flex flex-column text-start">
                                          <h6 className="max-content">
                                            Document No. : {data.documentNo}
                                          </h6>
                                          <a
                                            href="#"
                                            onClick={() =>
                                              openModal(
                                                "Visa Letter",
                                                data.visaLetter
                                              )
                                            }
                                          >
                                            visaLetter
                                          </a>{" "}
                                          <a
                                            href="#"
                                            onClick={() =>
                                              openModal(
                                                "Receipt",
                                                data.receiptFile
                                              )
                                            }
                                          >
                                            Receipt
                                          </a>{" "}
                                        </div>
                                      ) : (
                                        <div className="text-start">
                                          <h6>
                                            Ref No :{" "}
                                            <span className="text-secondary">
                                              {data.refNo}
                                            </span>
                                          </h6>
                                          <h6>
                                            Document No :{" "}
                                            <span className="text-secondary">
                                              {data.documentNo}
                                            </span>
                                          </h6>
                                          <h6 className="max-content">
                                            Date and Time :{" "}
                                            <span className="text-secondary">
                                              {data.acceptedAt &&
                                                data.acceptedAt.replace(
                                                  "T",
                                                  " "
                                                )}{" "}
                                            </span>
                                          </h6>
                                        </div>
                                      )}
                                    </>
                                  ) : (
                                    <div className="text-start">
                                      {data.remark && (
                                        <h6 className="mb-0">
                                          Remark:{" "}
                                          <span className="text-secondary">
                                            {data.remark}
                                          </span>
                                        </h6>
                                      )}
                                    </div>
                                  )}
                                </>
                              ) : (
                                <>
                                  {/* 
                                  -----------------------for Digital Copy--------------------
                                  -----------------------------------------------------------
                                  ----------------------------------------------------------- */}
                                  {data.deliveryMode === "Digital Copy" ? (
                                    <>
                                      {!data.receiptFile && (
                                        <>
                                          <div className="mb-2">
                                            <Form.Control
                                              type="text"
                                              placeholder="Receipt No."
                                              onChange={(e) =>
                                                handleInputChange(e, data._id)
                                              }
                                              value={data.receiptNo}
                                              name="receiptNo"
                                              maxLength={10}
                                            />{" "}
                                          </div>
                                          <div className="mb-2">
                                            <Form.Control
                                              type="text"
                                              placeholder="Document No."
                                              onChange={(e) =>
                                                handleInputChange(e, data._id)
                                              }
                                              value={data.documentNo}
                                              name="documentNo"
                                              maxLength={10}
                                            />{" "}
                                          </div>
                                          <div className="mb-2">
                                            <Form.Control
                                              type="datetime-local"
                                              onChange={(e) =>
                                                handleInputChange(e, data._id)
                                              }
                                              value={data.dateAndTime}
                                              name="dateAndTime"
                                            />{" "}
                                          </div>
                                          <div className="mb-2">
                                            <Form.Control
                                              type="text"
                                              placeholder="Paid By"
                                              onChange={(e) =>
                                                handleInputChange(e, data._id)
                                              }
                                              value={data.paidBy}
                                              name="paidBy"
                                              maxLength={10}
                                            />{" "}
                                          </div>
                                        </>
                                      )}

                                      <div className="mb-2">
                                        {data.visaLetter ? (
                                          <a
                                            className="max-content"
                                            href="#"
                                            onClick={() =>
                                              openModal(
                                                "Visa Letter",
                                                URL.createObjectURL(
                                                  data.visaLetter
                                                )
                                              )
                                            }
                                          >
                                            View VisaLetter
                                          </a>
                                        ) : (
                                          <>
                                            {data.documentNo && (
                                              <Button
                                                className="mb-2 rounded-5 max-content w-100"
                                                variant={
                                                  data.visaLetter
                                                    ? "success"
                                                    : "primary"
                                                }
                                                onClick={() =>
                                                  handleGenerateVisa(data._id)
                                                }
                                              >
                                                Generate Visa
                                              </Button>
                                            )}
                                          </>
                                        )}
                                      </div>

                                      {/* ------------------------------------generating reciept here ---------------------------
 ----------------------------------------------------------------------------------------
 ----------------------------------------------------------------------------------------- */}
                                      <div className="mb-2">
                                        {data.receiptFile ? (
                                          <a
                                            className="max-content"
                                            href="#"
                                            onClick={() =>
                                              openModal(
                                                "Receipt",
                                                URL.createObjectURL(
                                                  data.receiptFile
                                                )
                                              )
                                            }
                                          >
                                            View Receipt
                                          </a>
                                        ) : (
                                          <>
                                            {data.receiptNo &&
                                              data.documentNo &&
                                              data.dateAndTime &&
                                              data.visaLetter && (
                                                <Button
                                                  className="mb-2 rounded-5 max-content w-100"
                                                  onClick={() =>
                                                    generatePdf(data._id)
                                                  }
                                                >
                                                  Generate Receipt
                                                </Button>
                                              )}
                                          </>
                                        )}
                                      </div>
                                    </>
                                  ) : (
                                    <>
                                      <div className="mb-2">
                                        <Form.Control
                                          type="text"
                                          placeholder="Document No."
                                          onChange={(e) =>
                                            handleInputChange(e, data._id)
                                          }
                                          value={data.documentNo}
                                          name="documentNo"
                                          maxLength={10}
                                        />{" "}
                                      </div>

                                      <div className="mb-2">
                                        <Form.Control
                                          type="datetime-local"
                                          onChange={(e) =>
                                            handleInputChange(e, data._id)
                                          }
                                          value={data.dateAndTime}
                                          name="dateAndTime"
                                        />{" "}
                                      </div>
                                    </>
                                  )}
                                </>
                              )}
                              {/* <Modal
                                show={showPdfModal}
                                onHide={closePdfModal}
                                size="lg"
                                centered
                              >
                                <Modal.Header closeButton>
                                  <Modal.Title>PDF Preview</Modal.Title>
                                </Modal.Header>
                                <Modal.Body>
                                  {pdfBlob && (
                                    <PDFViewer
                                      style={{
                                        width: "100%",
                                        height: "100%",
                                      }}
                                    >
                                      <Document>
                                        <Page
                                          key={index}
                                          // size={[210, 145]}
                                          style={styles.page}
                                        >
                                          <View style={styles.section}>
                                            <Text>Hello</Text>
                                          </View>
                                          <View style={styles.section}>
                                            <Text>World</Text>
                                          </View>
                                        </Page>
                                      </Document>
                                    </PDFViewer>
                                  )}
                                </Modal.Body>
                                <Modal.Footer>
                                  <Button variant="primary" onClick={viewPdf}>
                                    Download PDF
                                  </Button>
                                  <Button
                                    variant="secondary"
                                    onClick={closePdfModal}
                                  >
                                    Close
                                  </Button>
                                </Modal.Footer>
                              </Modal> */}
                            </td>
                            <td>
                              <div className="max-content fw-bold">
                                {data.status === "Processing" ? (
                                  <>
                                    <div
                                      key={index}
                                      className="mb-2"
                                      style={{ textAlign: "left" }}
                                    >
                                      <Form.Label>Remark</Form.Label>
                                      <Form.Control
                                        as="textarea"
                                        rows={3}
                                        placeholder="Enter a Remark"
                                        onChange={(e) =>
                                          handleInputChange(e, data._id)
                                        }
                                        value={data.remark}
                                        name="remark"
                                        maxLength={75}
                                      />{" "}
                                    </div>
                                    {data.remark && (
                                      <div className="d-flex justify-content-evenly">
                                        <AiFillCheckCircle
                                          className="icon-size green-color cursor-pointer"
                                          onClick={() =>
                                            handleAcceptVisa(data._id)
                                          }
                                        />
                                        <AiFillCloseCircle
                                          className="icon-size text-danger cursor-pointer"
                                          onClick={() =>
                                            handleRejectVisa(data._id)
                                          }
                                        />
                                      </div>
                                    )}
                                  </>
                                ) : (
                                  <>
                                    <div className="text-start">
                                      <h6>{data.status}</h6>

                                      <span className="text-success">
                                        {new Intl.DateTimeFormat("en-GB", {
                                          year: "numeric",
                                          month: "numeric",
                                          day: "numeric",
                                          hour: "numeric",
                                          minute: "numeric",
                                        }).format(new Date(data.updatedAt))}
                                      </span>
                                    </div>
                                  </>
                                )}
                              </div>
                            </td>
                          </tr>
                        ));
                      } else {
                        return (
                          <tr>
                            <td colSpan="9" className="text-center">
                              No matching data
                            </td>
                          </tr>
                        );
                      }
                    })()
                  ) : (
                    <tr>
                      <td colSpan="9" className="text-center">
                        No data available
                      </td>
                    </tr>
                  )}

                  <FileViewModal
                    show={showModal}
                    onHide={closeModal}
                    title={modalTitle}
                    fileLink={file}
                  />
                </tbody>
              </Table>
            ) : (
              <p>No data available</p>
            )}

            {totalPages > 1 && (
              <div className="d-flex justify-content-start mt-2">
                <Pagination>
                  <Pagination.Prev
                    onClick={handlePrevPage}
                    disabled={currentPage === 1}
                  />
                  {Array.from(
                    { length: endPage - startPage + 1 },
                    (_, index) => (
                      <Pagination.Item
                        key={index + startPage}
                        active={index + startPage === currentPage}
                        onClick={() => handlePageChange(index + startPage)}
                      >
                        {index + startPage}
                      </Pagination.Item>
                    )
                  )}
                  <Pagination.Next
                    onClick={handleNextPage}
                    disabled={currentPage === totalPages}
                  />
                </Pagination>
              </div>
            )}
          </div>
        </div>
      </Layout>
    </>
  );
};

const styles = StyleSheet.create({
  page: {
    size: "A4",
    flexDirection: "row",
    backgroundColor: "#ffffff", // Set background color if needed
  },
  section: {
    margin: 10,
    padding: 10,
    flexGrow: 1,
  },
});

export default RecommendationLetter;

//suppot functions
function convertAmountToWords(amount) {
  const units = [
    "",
    "One",
    "Two",
    "Three",
    "Four",
    "Five",
    "Six",
    "Seven",
    "Eight",
    "Nine",
  ];
  const teens = [
    "",
    "Eleven",
    "Twelve",
    "Thirteen",
    "Fourteen",
    "Fifteen",
    "Sixteen",
    "Seventeen",
    "Eighteen",
    "Nineteen",
  ];
  const tens = [
    "",
    "Ten",
    "Twenty",
    "Thirty",
    "Forty",
    "Fifty",
    "Sixty",
    "Seventy",
    "Eighty",
    "Ninety",
  ];

  function convertGroupToWords(num) {
    if (num === 0) return "";
    else if (num < 10) return units[num];
    else if (num < 20) return teens[num - 10];
    else {
      const tenDigit = Math.floor(num / 10);
      const unitDigit = num % 10;
      return `${tens[tenDigit]} ${units[unitDigit]}`;
    }
  }

  function convertToWords(num) {
    if (num === 0) return "Zero";
    const billion = Math.floor(num / 1000000000);
    const million = Math.floor((num % 1000000000) / 1000000);
    const thousand = Math.floor((num % 1000000) / 1000);
    const remainder = num % 1000;

    let result = "";

    if (billion > 0) result += `${convertGroupToWords(billion)} Billion `;
    if (million > 0) result += `${convertGroupToWords(million)} Million `;
    if (thousand > 0) result += `${convertGroupToWords(thousand)} Thousand `;
    if (remainder > 0) result += convertGroupToWords(remainder);

    return result.trim();
  }

  return convertToWords(amount);
}

const blobToFile = (blob, fileName) => {
  // Convert the blob to a File object
  const file = new File([blob], fileName, { type: "application/pdf" });
  return file;
};
